<template>
  <page-with-back-btn :title="`Tickets #${id}`">
    <div class="d-flex">
      <div class="m-3 flex-grow-1">
        <h4>{{ ticket.title }}</h4>
        <hr/>
        <div>
          <p>Status : <span :class="`badge `+(ticket.status===`CLOSED`?`bg-success`:`bg-warning`)">{{ ticket.status }}</span></p>
          <p class="mb-0">Store : {{ ticket.store ? ticket.store : `No Store Specified` }}</p>
          <line-separator title="Ticket Info"/>
          <p class="mb-0">Type : {{ ticket.category }}</p>
          <p class="mb-0">Sub-Type : {{ ticket.subCategory }}</p>
          <p class="mb-0">Assigned To : {{ ticket.createdBy }}</p>
          <p class="mb-0" v-if="ticket.createdDate">Reported Date : {{ $utils.displayDate(ticket.createdDate , true)  }}</p>
        </div>
        <div v-if="ticket.descripton">
          <line-separator title="Ticket Description"/>
          <div v-html="ticket.description"></div>
        </div>
        <hr/>
      </div>
    </div>
    <floating-add-button icon="pen-to-square" @click="editTicket" v-if="ticket.status !== `CLOSED`"/>
  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn";
import {BootstrapVueIcons} from 'bootstrap-vue';
import LineSeparator from "@/components/layout/utils/LineSeparator";

export default {
  name: "TicketDetails",
  components: {LineSeparator, PageWithBackBtn, FullPage, FloatingAddButton, 'b-icon': BootstrapVueIcons.BIcon},
  beforeMount() {
    this.getTicketDetails();
  },
  data() {
    return {
      ticket: {},
      id: this.$route.params.id,
      isEditing: false,
      editableRow: [],
      userComment: '',
      showTicketDetails: false
    };
  },
  computed: {},
  methods: {
    startEditing(row) {
      this.isEditing = true;
      this.editableRow = row.details.map(detail => detail.value); // Populate editableRow with current values
    },
    editTicket() {
      return this.$router.push({
        name: 'Create-Ticket',
        query: {id: this.id}
      });
    },
    saveChanges(row) {
      this.isEditing = false;
      row.details = row.details.map((detail, index) => ({
        ...detail,
        value: this.editableRow[index]
      }));
    },
    openNotesModal(expenseId) {
      // Implement logic to open notes modal
      console.log('Open notes for expense with ID:', expenseId);
    },
    openAttachmentDialog(expenseId) {
      // Implement logic to open file attachment dialog
      console.log('Open attachment dialog for expense with ID:', expenseId);
    },
    toggleTicketDetails() {
      this.showTicketDetails = !this.showTicketDetails;
    },
    getTicketDetails() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getTicketDetails`, this.id)
          .then((res) => this.ticket = res)
          .finally(() => this.$utils.hideLoading())
    }
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  align-items: center;
}

.card {
  margin: 0;
  padding: 0;
}

.card-header {
  border-bottom: none;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.comment-section {
  display: flex;
  align-items: center;
}

.comment-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.file-upload-section {
  margin-top: 1rem;
}

.file-upload-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.file-upload-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}
</style>

