import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'stock-transfer',
    state: {
        allStores: [],
        franchiseSetting: null,
        itemsToSend: [],
        isSavingTransfer: false,
        currentSendingItem: undefined,
    },
    getters: {
        getStores: (state) => state.allStores,
        getCurrentSendingItem: (state) => state.currentSendingItem,
        getFranchiseSettings: (state) => state.franchiseSetting,
        getItemsToSend: (state) => state.itemsToSend,
        isCurrentlySavingTransfer: (state) => state.isSavingTransfer,
    },
    mutations: {
        setIsSavingTransfer: (state, payload) => state.isSavingTransfer = payload,
        setFranchiseSettings: async (state, payload) => {
            if (payload) {
                await Storage.setFranchiseSettings(payload)
            } else {
                payload = await Storage.getFranchiseSettings();
            }
            state.franchiseSetting = payload;
        },

        setCurrentSendingItem: async (state, payload) => {
            payload.index = state.itemsToSend.length;
            if (payload) {
                await Storage.setSendingItem(payload)
            } else {
                payload = await Storage.getSendingItem();
            }
            state.currentSendingItem = payload;
        },
        clearSendingItem: async (state) => {
            state.currentSendingItem = undefined
            await Storage.setSendingItem(undefined)
        },
        setAllStores: async (state, payload) => {
            if (!payload) {
                let stores = await Storage.getAllStores();
                if (!stores) {
                    let defaultStore = await Storage.getCurrentStore();
                    stores = [];
                    stores.push(defaultStore);
                }
                payload = stores;
            } else {
                await Storage.setAllStores(payload);
            }
            state.allStores = payload;
        },
        addToItemsToSend: (state, payload) => {
            let index = payload.index
            let check = state.itemsToSend.find(item => item.index === index)
            if (check && payload.onlyInstruction) {
                if (payload.instructions) {
                    const data = [...payload.instructions, ...check.instructions];
                    let edited = data.reduce((accumulator, msg) => {
                        if (accumulator.has(msg.CookingNum)) {
                            let m = accumulator.get(msg.CookingNum)
                            m.count += msg.count
                        } else {
                            accumulator.set(msg.CookingNum, msg);
                        }
                        return accumulator;
                    }, new Map())
                    payload.instructions = [...edited.values()]
                }
                state.itemsToSend = state.itemsToSend.filter(stockItem => stockItem.index !== index);
            }
            state.itemsToSend.push(payload)
        },
        removeFromItemsToSend: (state, index) => state.itemsToSend = state.itemsToSend.filter(item => item.index !== index),
        clearItemsToSend: (state) => state.itemsToSend = [],
    },
    actions: {
        getAllFranchiseSettings: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            return axios.get(franchiseTomcatApi + `franchise_settings/${storeDB}`)
                .then(({data}) => context.commit(`setFranchiseSettings`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
        getAllStores: async (context) => {
            let store = await Storage.getCurrentStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `stores/getByGroup`, {
                params: {group: store.Group}
            }).then(({data}) => context.commit(`setAllStores`, data.sort((a, b) => a.StoreName.localeCompare(b.StoreName))));
        },
        getIncomingTransfers: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `franchise_stockpending/${storeDB}/search`, payload)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getRejectedTransfers: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `franchise_stocksent/${storeDB}/search`, payload)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        markTransferAsEdited: async (context, stockSentID) => {
            $utils.showLoading()
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser()
            return axios.get(franchiseTomcatApi + `franchise/editReject/${storeDB}/${stockSentID}/${user.Username}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
                .finally(() => $utils.hideLoading())
        },

        getSavedTransfers: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = context.getters.getGlobalDateRange;
            obj.id = payload.id;
            obj.Description = payload.Description;
            return axios.get(franchiseTomcatApi + `Franchise_PendingTransfer/${storeDB}/search`, {
                params: obj
            }).then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getTransferByDocument: async (context, obj) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `franchise_transfer/${storeDB}/transferLine/${obj.documentNumber}?senderID=${obj.senderID}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        deleteTransfer: async (context, documentNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `franchise_stocksent/${storeDB}/deleteTransferByDocumentNumber?documentNumber=` + documentNumber)
                .catch(err => errorHandler.tomcatError(err, true))
        },

        rejectTransferByDocumentNumber: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `franchise_stockpending/${storeDB}/rejectTransferByDocumentNumber`, payload)
                .catch(err => errorHandler.tomcatError(err, true))
        },

        acceptTransferByDocumentNumber: async (context, obj) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser()
            let payload = {
                documentNumber: obj.documentNumber,
                senderID: obj.senderID,
                user: user.Username,
                pc: 'Online'
            }
            return axios.post(franchiseTomcatApi + `franchise_stocksent/${storeDB}/acceptTransferByDocumentNumber`, payload)
                .catch(err => errorHandler.tomcatError(err, true))
        },
        transferStock: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let obj = {
                storeID: payload.storeID,
                saleNum: payload.saleNum,
                items: context.getters.getItemsToSend,
                user: user.Username,
                pc: 'Online'
            };
            return axios.post(franchiseTomcatApi + `franchise_stocksent/${storeDB}/inititateTransfer`, obj)
                .then(({data}) => {
                    context.commit(`clearItemsToSend`);
                    // Assuming your API returns the document number in the response
                    let documentNumber = data; // Adjust according to your API response
                    // You can use this documentNumber as needed
                    return documentNumber;
                })
                .catch(err => errorHandler.tomcatError(err));
        },

        saveStockTransfer: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser()
            let obj = {
                saleNum: payload.saleNum,
                storeID: payload.storeID,
                description: payload.description,
                items: context.getters.getItemsToSend,
                user: user.Username,
                pc: 'Online'
            };
            return axios.post(franchiseTomcatApi + `Franchise_PendingTransfer/${storeDB}/saveTransferOnline`, obj)
                .then(({data}) => {
                    context.commit(`clearItemsToSend`)
                    return context.dispatch(`printTransfer`, {
                        id: data,
                        StoreDB: storeDB
                    });
                })
                .catch(err => errorHandler.tomcatError(err, true))
        },
        getStockInBothStores: async (context, payload) => {
            let store = await Storage.getCurrentStore();
            let senderID = store.CustomerID;
            let receiverID = payload.storeID;
            let searchFilter = {
                Plu: payload.Plu,
                Description: payload.Description,
                limit: 50
            }
            return axios.post(franchiseTomcatApi + `franchise/search_stock/match/${senderID}/${receiverID}`, searchFilter)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getStockItemInBothStores: async (context, payload) => {
            let store = await Storage.getCurrentStore();
            let senderID = store.CustomerID;
            let receiverID = payload.storeID;
            let searchFilter = {
                Plu: payload.Plu,
            }
            return axios.get(franchiseTomcatApi + `franchise/search_stock/matchOne/${senderID}/${receiverID}`, {
                params: searchFilter
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        getCookingInstruction: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(serverApi + `cookinginstruction/${storeDB}/${payload}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getNotReceived: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `pending/getBySenderDB/${storeDB}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getItemsByDocumentNumber: async (context, documentNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `franchise_transfer/${storeDB}/getItemsByDocumentNumberFromSender?documentNumber=` + documentNumber)
                .then(({data}) => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].index = i;
                        context.commit(`addToItemsToSend`, data[i])
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },
        printTransfer: (context, payload) => {
            let id = payload.id;
            let DB = payload.StoreDB;
            return openUrl(franchiseApi + `PDFReport/viewTransferRecord/${id}/${DB}`);
        },


        getStoreGroups: async () => {
            let store = await Storage.getCurrentStore();
            return axios.get(regApi + `Franchise/getAll/${store.Group}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },
        updateStoreGroup: async (context, payload) =>{
            let id = payload.id;
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(regApi + `Franchise/Group/${id}`,payload)
                .then(async ()=>{
                    axios.get(franchiseTomcatApi+`franchise_settings/${storeDB}/auditFranchiseSettings`)
                }).catch(err => errorHandler.tomcatError(err))
        },

        getUngroupedStores: async () =>{
            let store = await Storage.getCurrentStore();
            return axios.get(regApi + `Franchise/getUnGrouped/${store.Group}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getStoresByGroup: async (context , groupName) =>{
            let store = await Storage.getCurrentStore();
            return axios.get(regApi + `Franchise/getByStoreGroup/${store.Group}/${groupName}`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        createFranchiseGroup: async (context , payload) =>{
            let store = await Storage.getCurrentStore();
            payload.reg_group = store.Group;
            return axios.post(regApi + `Franchise/Group`,payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        addStoreToFranchiseGroup : async (context , payload) =>{
            return axios.post(regApi + `Franchise/Store`,payload)
                .catch(err => errorHandler.tomcatError(err))
        },
        removeStoreFromFranchiseGroup : async (context , id) =>{
            return axios.delete(regApi + `Franchise/Store/${id}`)
                .catch(err => errorHandler.tomcatError(err))
        },



    }
}
