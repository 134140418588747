<template>
  <full-page title="My Invoices">
    <RangeSelector :submit-method="getInvoices"/>
    <div class="card mx-2 mb-2">
      <form @submit.prevent="getInvoices" class="card-body">
        <div class="row mb-2">
          <div class="col-md-3">
            <label for="floatingTextInput1">Invoice No.</label>
            <input type="text" class="form-control" v-model="invoice_no" id="floatingTextInput1" placeholder="Invoice No.">
          </div>
          <div class="col-md-3">
            <label for="pay-type">Payment Method</label>
            <select class="form-select" v-model="payType" id="pay-type">
              <option value="">All</option>
              <option v-for="method in methods" :value="method">{{ method }}</option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="sale-total">Sale Total</label>
            <input type="number" v-model="saleTotal" step="0.01" placeholder="Sale Total" id="sale-total" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="search-limit">Limit</label>
            <input type="number" v-model="limit" placeholder="Limit" id="search-limit" class="form-control">
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary w-75" type="submit">Search</button>
        </div>
      </form>
    </div>
    <line-separator :title="`Invoices (${invoices.length} Found)`" class="mx-3" />

    <InvoiceRecord v-for="item in invoices" @click="openInvoice(item.InvNum)" :sale="item" :key="item.InvNum"/>
  </full-page>
</template>
<script>
import axios from 'axios';
import InvoiceRecord from "@/components/point-of-sale/sales/Invoices/InvoiceRecords";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import LineSeparator from "@/components/layout/utils/LineSeparator";
import FullPage from "@/components/layout/utils/FullPage";
import { Storage } from "@/Services/Storage";

export default {
  name: "SalesRecords",
  components: { FullPage, LineSeparator, RangeSelector, InvoiceRecord },
  computed: {
    methods() {
      let payMethods = [`Cash`, `Card`, `Kazang`];
      let paymentMethods = this.$store.getters.getPaymentMethods.map(method => method.Name);
      return [...payMethods, ...paymentMethods];
    },
  },
  data() {
    return {
      invoice_no: '',
      payType: '',
      saleTotal: '',
      limit: 10,
      invoices: [],
    }
  },
  beforeMount() {
    this.getInvoices();
  },
  methods: {
    async getInvoices() {
      try {
        let DB = await Storage.getCurrentStoreDB();
        DB = window.btoa(DB);
        let config = {
          headers: {
            DB
          },
          params: {
            InvNum: this.invoice_no,
            PaymentType: this.payType,
            SaleTotal: this.saleTotal.trim() === '' ? null : Number(this.saleTotal),
            limit: this.limit ? this.limit : null,
            ...this.$store.getters.getGlobalDateRange,
          }
        };

        this.$utils.showLoading();
        const response = await axios.get(ticketApi + '/InvNum/SaleTotal', config);  // Adjusted the endpoint to '/api/invoices/populate'
        this.$utils.hideLoading();
        this.invoices = response.data;
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
    openInvoice(inv) {
      return this.$store.dispatch('printInvoice', { invoice: inv, reprint: true });
    },
  }
}
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-box {
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}

.col-md-4 {
  margin-bottom: 10px;
}
</style>
