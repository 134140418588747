import {Storage} from "../../../Services/Storage";
import axios from "axios";
import router from "../../../router";

export default {
    name: 'user',
    state: {
        allUsers: [],
        currentUser: {}
    },
    getters: {
        getAllUsers: (state) => state.allUsers,
        getCurrentUser: (state) => state.currentUser,
    },
    mutations: {
        setAllUsers: async (state, payload) => {
            if (payload) await Storage.setAllUsers(payload)
            else payload = await Storage.getAllUsers();
            state.allUsers = payload ? payload : [];
        },

        setCurrentUser: async (state, payload) => {
            if (payload) await Storage.setCurrentUser(payload)
            else payload = await Storage.getCurrentUser();
            state.currentUser = payload ? payload : {};
        },
    },
    actions: {
        getAllUsers: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `users/${storeDB}/getAll`)
                .then(({data}) => context.commit('setAllUsers', data))
                .catch(err => errorHandler.tomcatError(err))
        },
        createEmployee: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `employee/${storeDB}`, payload)
                .then(() => context.dispatch('getAllUsers'))
                .catch((err) => errorHandler.tomcatError(err))
        },
        editEmployee: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi + `employee/${storeDB}/${payload.id}`, payload)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        deleteEmployee: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `employee/${storeDB}/${id}`)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },
        validateUser: async (context, password) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return router.push({name: 'login'})
            let users = await Storage.getAllUsers();
            let currentUser = users.find(user => user.password.toLowerCase() === password.toLowerCase());
            if (currentUser) {
                await context.commit(`setCurrentUser`, currentUser)
                context.dispatch(`checkSmartInvoicing`);
                return true;

            }
            return false;
        }
    }
}
