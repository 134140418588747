import {Storage} from "@/Services/Storage";
import axios from "axios";
import router from "@/router";

export default {
    name: 'settings',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getEmail: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`emailrecord/${storeDB}/getEmailSettings`)
                .then(({data}) => data);
        },
        getStoreLogo: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(stocklistApi + `settings/${storeDB}/getLogo`)
                .then(({data}) => data);
        },
        setStoreLogo: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(stocklistApi + `settings/${storeDB}/addLogo`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Set Logo`));
        },
        getOnlineSettings: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `settings/${storeDB}/Online`)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        saveBasicSettings: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let userDetails = await Storage.getMobileUser();
            return axios.post(stocklistApi + `settings/${storeDB}/saveBasicSettings`, payload)
                .then(() => {
                    return Promise.all([
                        context.dispatch(`refreshState`),
                        context.dispatch(`mobileUserLogin`, userDetails)
                    ])
                })
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Save Settings`));
        },

        saveEmailSetup: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
             return axios.post(`https://realtime.abc.co.zm:8443/Franchise_OnlineServer/emailrecord/${storeDB}/setEmailSettings`, payload)
                .catch((err) => errorHandler.tomcatError(err, false, `Could not Save Email settings`));
        },
        requestPasswordReset: async (context, username) => {
            return axios.post(franchiseTomcatApi + `SetKazangUser/${username}/forgotPasswordRequest`, {
                username: username
            }).catch(err => errorHandler.tomcatError(err))
        },

        resetPassword: async (context, payload) => {
            return axios.post(franchiseTomcatApi + `SetKazangUser/${payload.username}/resetPassword`, payload)
                .then(() => router.push({name: 'login'}))
                .catch(err => errorHandler.tomcatError(err))
        },
        auditVAT: async (context, range) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `invoice/${storeDB}/auditVATInvoices?from=${range.from}&to=${range.to}`)
                .catch(err => errorHandler.tomcatError(err))
        }
    }
}
